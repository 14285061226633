import React, { Component } from 'react'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from "gatsby"

const App = ({data, pageContext, props}) => {
  return(
    <Layout>
    <SEO title={`products`}/>
    <div className="wrapper" style={{padding: '3rem'}}>
      <div className="content m-4">
        <div className="row">
          {data.allMarkdownRemark.edges.map((edge)=>{
            let datePath = edge.node.fileAbsolutePath.replace(/^.*[\\\/]/, '').replace('.md','')
            let path = '/contest-winner/' + datePath
            let productTitle = edge.node.frontmatter.product_title
            return (<Link to={path}>{productTitle + ' - ' + datePath }</Link>)
          })}
          <div className="col text-left">
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export default App

export const query = graphql`
query allProductsQuery {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "^/product/"}}
    sort: {order: ASC, fields: fileAbsolutePath}) {
    edges {
      node {
        id
        frontmatter {
          product_title
          title
        }
        fileAbsolutePath
      }
    }
  }
}
`
